import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['paymentMethodForm', 'error'];

  async paymentFormSubmit(event, elements, return_url) {
    event.preventDefault();
    document.getElementById('stripeMainComponent')

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: return_url }
    });

    if (error) { this.errorTarget.textContent = error.message; }
  }

  connect() {
    const stripeMainComponent = document.getElementById('stripeMainComponent')

    const appearance = {
      theme: 'flat',
      variables: {
        colorPrimary: '#00D1B2',
        colorBackground: '#FFFCF9',
        colorText: '#30313d',
        colorDanger: '#df1b41',
      }
    };
    const elements = stripe.elements({
      'clientSecret': stripeMainComponent.dataset.clientSecret,
      'appearance': appearance
    });

    var paymentMethod = elements.create('payment');
    paymentMethod.mount("#paymentMethod");

    this.paymentMethodFormTarget.addEventListener('submit', async (event) => {
      this.paymentFormSubmit(event, elements, stripeMainComponent.dataset.redirectUrl)
    })
  }
}
