import { Controller } from '@hotwired/stimulus';

export default class PagePreviewer extends Controller {
  static targets =  ['small', 'mid', 'large', 'previewer']
  connect() {
    this.previewerTarget.style.width = `${window.innerWidth}px`;

    this.#add_listener(this.smallTarget, '425px');
    this.#add_listener(this.midTarget, '808px');
    this.#add_listener(this.largeTarget, window.innerWidth > 1024 ? `${window.innerWidth}px` : '1024px');
  }

  #resize_previewer(previewer, width) {
    previewer.style.width = width || `${window.innerWidth}px`;

    if(width === '425px') {
      switch(previewer.dataset.sectionType) {
        case 'text_only':
          previewer.querySelector('#section_container').classList.remove('lg:tw-w-4/5');
          break;
        case 'text_image':
          previewer.querySelector('#section_container').classList.remove('md:tw-grid-cols-2');
          break;
        case 'socials':
          previewer.querySelector('#section_container').classList.remove('md:tw-grid-cols-2');
          break;
      }
    } else {
      switch(previewer.dataset.sectionType) {
        case 'text_only':
          previewer.querySelector('#section_container').classList.add('lg:tw-w-4/5')
          break;
        case 'text_image':
          previewer.querySelector('#section_container').classList.add('md:tw-grid-cols-2');
          break;
        case 'socials':
          previewer.querySelector('#section_container').classList.add('md:tw-grid-cols-2');
          break;
      }
    }
  }

  #add_listener(target_element, width) {
    target_element.addEventListener('click', () => {
      this.#resize_previewer(this.previewerTarget, width)
    })
  }
}