import { Controller } from '@hotwired/stimulus';
import { get, put } from '@rails/request.js';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import plLocale from '@fullcalendar/core/locales/pl';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

const reservation_id = window.location.pathname.split('/').pop();

export default class extends Controller {
  static targets = [];

  calendar = ''

  async createUnavailableDay(date) {
    const response = await put(
      `/admin/reservations/${reservation_id}.js`,
      {
        body: { 'book_anything_reservation': date },
        responseKind: 'js',
        contentType: 'application/json'
      }
    );

    if (response.ok) { const body = await response.text }
    else { console.log(response) }
  }

  async renderEventInfo(order_id) {
    let _self = this;

    const response = await get(
      `/admin/reservations/${reservation_id}/reservation_order_info/?order_id=${order_id}`,
      { responseKind: 'turbo-stream' }
    )

    if (response.ok) { const body = await response.text }
    else { console.log(response) }
  }

  connect(){
    let _self = this;
    let format_unavailable_days = (events) => {
      let unavailable_days = [];
      
      events.forEach((event) => {
        if (event.startStr.trim() !== '' && event.endStr.trim() !== '')
          unavailable_days.push({ start: event.startStr, end: event.endStr })
      });

      return unavailable_days
    }

    let calendarEl = document.getElementById('calendar');
    this.calendar = new Calendar(calendarEl, {
      timeZone: 'UTC',
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
      initialView: 'dayGridMonth',
      locales: [ plLocale ],
      locale: 'en',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek'
      },
      dayMaxEvents: 3,
      editable: false,
      selectable: true,
      selectOverlap: false,
      select: function(info) {
        const start_date = new Date(info.startStr)
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if(start_date < today) {
          alert("You can only select today or a later date.");
        } else {
          let unavailable_days = format_unavailable_days(_self.calendar.getEvents());

          unavailable_days.push({ start: info.startStr, end: info.endStr });
          _self.calendar.addEvent({ start: info.startStr, end: info.endStr, title: 'unavailable',  id: 'unavailable' });
          _self.createUnavailableDay({ unavailable_days: unavailable_days });
        }        
      },
      eventClick: function(info) {
        if (info.event._def.publicId == 'unavailable') {
          info.event.remove()
          _self.createUnavailableDay({unavailable_days: format_unavailable_days(_self.calendar.getEvents())});
        } else {
           _self.renderEventInfo(info.event._def.publicId)
        }
      },
    });

    this.calendar.addEventSource(`/admin/reservations/${reservation_id}.js`);
    this.calendar.render();

    new Draggable(document.getElementById('external-events'));

    let url = new URL(window.location.href);
    if (url.searchParams.has('order_id')) {
      _self.renderEventInfo(url.searchParams.get('order_id'));
    }
  }

}
