import { Controller } from '@hotwired/stimulus';
import * as req from '@rails/request.js';

export default class extends Controller {

  async destroyPaymentMethod(event) {
    const paymentMethodId = event.currentTarget.parentElement.dataset.paymentMethodId;

    const request = new req.FetchRequest('delete', `/admin/payment_methods/${paymentMethodId}`, {
      responseKind: 'html',
      method: 'delete',
    });

    const response = await request.perform()
    if (response.ok) { window.location.href = '/admin/billing' } else {
    }
  }
}
