import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = ['stepper', 'stepDialogs', 'submitButton']

  activateStep(e) {
    e.currentTarget.classList.remove('tw-bg-transparent');
    e.currentTarget.classList.add('tw-bg-jalappeno-primary');
    e.currentTarget.classList.add('tw-text-white');

    const stepDialog = this.stepDialogsTarget.querySelector("[id='" + e.currentTarget.dataset.target +"']");
    var stepNumber = stepDialog.dataset.stepComponentStepNumberValue;

    if(parseInt(stepNumber) >= 3) {
      this.submitButtonTarget.classList.remove('tw-hidden')
    } else { this.submitButtonTarget.classList.add('tw-hidden') }

    this.stepDialogsTarget.querySelectorAll('[data-step-component-target]').forEach(e => {
      this.hideDialog(e.parentElement);
    })

    this.showDialog(stepDialog.parentElement);
  }

  showDialog(element) {
    element.classList.remove('tw-opacity-0', 'tw-hidden');
    element.classList.add('tw-opacity-100');
  }

  hideDialog(element) {
    element.classList.add('tw-hidden', 'tw-opacity-0');
    element.classList.remove('tw-opacity-100');
  }

  serializeForm(form) {
    var obj = {};
    var formData = new FormData(form);
    for (var key of formData.keys()) { obj[key] = formData.get(key); }
    return obj;
  };

  submitOnboarding() {
    const allForms = document.querySelectorAll('form')
    var combinedForms = new FormData;

    allForms.forEach((form) => {
      var formData = new FormData(form);

      for (const pair of formData.entries()) {
        combinedForms.append(pair[0], pair[1]);
      }
    })

    post("/onboard", {
      body: combinedForms,
      responseKind: 'turbo-stream'
    })
  }

  connect() {
    const allComponents = this.stepDialogsTarget.querySelectorAll('[data-step-component-step-number-value]');
    
    allComponents.forEach(e => {
      e.parentElement.classList.add('tw-hidden')
      e.parentElement.classList.add('tw-opacity-0')
      if(e.dataset.stepComponentStepNumberValue == '0') {
        e.parentElement.classList.remove('tw-hidden')
        e.parentElement.classList.remove('tw-opacity-0')
      }
    })
  }
}
