import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['createPayment', 'qrCode'];

  async submitPaymentForm(event) {
    await fetch(event.target.action, {
      method: 'POST',
      body: new URLSearchParams(new FormData(event.target))
    }).then((response) => {
      if (!response.ok) { throw new Error(`HTTP error! Status: ${response.status}`); }
      return response.json();
    }).then((body) => {
      this.qrCodeTarget.src = body.url + '?random=' + new Date().getTime();
    }).catch((error) => {

    });
  }

  connect() {
    this.createPaymentTarget.addEventListener('submit', (event) => {
      event.preventDefault();
      this.submitPaymentForm(event);
    });
  }
}
