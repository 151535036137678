import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['list', 'placeholderEmpty', 'placeholderSelected']

  toggleList() {
    let list = this.listTarget;

    if(list.children.length > 0) {
      list.hidden = !list.hidden;
    }
  }

  hideList() {
    this.listTarget.hidden = true;
  }

  checkCheckboxes() {
    let items = this.listTarget.children;
    let checked = false;
    let number_of_checked = 0;
    for (let item of items) {
      if (item.querySelector('input').checked) {
        checked = true;
        number_of_checked++;
      }
    }
    if (number_of_checked <= 1) {
      this.placeholderSelectedTarget.querySelector('span').textContent = "Item selected";
    } else {
      this.placeholderSelectedTarget.querySelector('span').textContent = `${number_of_checked} items selected`;
    };
    this.placeholderSelectedTarget.hidden = true;
    if (checked == true) {
      this.placeholderEmptyTarget.hidden = true;
      this.placeholderSelectedTarget.hidden = false;
    } else {
      this.setEmptyPlaceholder();
    }
  }

  setEmptyPlaceholder() {
    this.placeholderEmptyTarget.hidden = false;
    this.placeholderSelectedTarget.hidden = true;
  }

  connect() {
    this.hideList();
    this.setEmptyPlaceholder();
    this.listTarget.addEventListener('focusout', () => {
      if (event.relatedTarget == null ) {
        this.hideList();
      }
    } );
    this.checkCheckboxes();

    let items = this.listTarget.children;
    for (let item of items) {
      item.querySelector('input').addEventListener('click', () => {  this.checkCheckboxes(); } );
    }
  }
}