import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'buttons', 'form'];

  sendData() {
    if(this.inputTarget.value.length > 2) {
      this.formTarget.submit();
    }
  }

  connect() {
    const input = this.inputTarget;
    const buttons = this.buttonsTarget.querySelectorAll('button')

    buttons.forEach((button) => {
      button.addEventListener('click', (event) => {
        if(event.currentTarget.value === 'backspace') {
          if(input.value.length === 0) return;
          input.value = input.value.slice(0, -1)
        }

        input.value += event.currentTarget.firstChild.innerText

      }, { capture: false })
    })
  }
}
