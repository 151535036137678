['turbo:frame-load', 'turbo:load'].forEach(evt => {
  document.addEventListener(evt, function () {

    /* NavBar menu mobile toggle */
    Array.from(document.getElementsByClassName('jb-navbar-menu-toggle')).forEach(el => {
      el.addEventListener('click', e => {
        const dropdownIcon = e.currentTarget
            .getElementsByClassName('icon')[0]
            .getElementsByClassName('mdi')[0]

        document.getElementById(e.currentTarget.getAttribute('data-target')).classList.toggle('is-active')
        dropdownIcon.classList.toggle('mdi-dots-vertical')
        dropdownIcon.classList.toggle('mdi-close')
      })
    })

    /* Modal: open */
    Array.from(document.getElementsByClassName('jb-modal')).forEach(el => {
      el.addEventListener('click', e => {
        const modalTarget = e.currentTarget.getAttribute('data-target')

        if(!modalTarget) return;
        document.getElementById(modalTarget).classList.add('is-active')
        document.documentElement.classList.add('is-clipped')
      })
    });

    /* Modal: close */
    Array.from(document.getElementsByClassName('jb-modal-close')).forEach(el => {
      el.addEventListener('click', e => {
        e.currentTarget.closest('.modal').classList.remove('is-active')
        document.documentElement.classList.remove('is-clipped')
      })
    })

    /* Notification dismiss */
    Array.from(document.getElementsByClassName('jb-notification-dismiss')).forEach(el => {
      el.addEventListener('click', e => {
        e.currentTarget.closest('.notification').classList.add('is-hidden')
      })
    })

    Array.from(document.getElementsByClassName('modal-form-submit')).forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault();
        e.currentTarget.parentElement.parentElement.querySelector('form').submit();
      })
    })

    /* Notification dismiss */
    Array.from(document.getElementsByClassName('jb-message-dismiss')).forEach(el => {
      el.addEventListener('click', e => {
        e.currentTarget.parentElement.nextElementSibling.classList.toggle('is-message-hidden');
        e.currentTarget.parentElement.nextElementSibling.classList.toggle('is-message-active');
      })
    })

    Array.from(document.getElementsByClassName("move_freely")).forEach(el => {
      var mousePosition;
      var offset = [0,0];
      var div = el;
      var isDown = false;
      div.style.position = "absolute";

      div.addEventListener('mousedown', function(e) {
         isDown = true;
          offset = [
              div.offsetLeft - e.clientX,
              div.offsetTop - e.clientY
          ];
      }, true);

      document.addEventListener('mouseup', function() {
          isDown = false;
      }, true);

      document.addEventListener('mousemove', function(event) {
          event.preventDefault();
          if (isDown) {
              mousePosition = {
          
                  x : event.clientX,
                  y : event.clientY
          
              };
              div.style.left = (mousePosition.x + offset[0]) + 'px';
              div.style.top  = (mousePosition.y + offset[1]) + 'px';
          }
      }, true);
    })
  });
})