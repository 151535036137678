import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["button"];

  click (event) {
    if (this.buttonTarget.type === "submit" && this.buttonTarget.form) {
      return true;
    }

    event.preventDefault();
    const clickedEvent = new CustomEvent(this.buttonTarget.name, {
      detail: {
        target: this.buttonTarget,
      },
    });
    document.dispatchEvent(clickedEvent);
  }
}
