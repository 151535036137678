import {Controller} from "@hotwired/stimulus";

export default class HideFields extends Controller {
  static targets = [ 'hideElement' ]
  connect() {
    let hidingTarget = this.hideElementTarget;
    this.#hide_fields(hidingTarget);
    this.hideElementTarget.addEventListener('change', () => this.#hide_fields(hidingTarget));
  }

  #hide_fields(hiding_target) {
    let target_to_hide = this.element.querySelectorAll(hiding_target.dataset.hideSelector);
    let toggle_targets = this.element.querySelectorAll(hiding_target.dataset.toggleSelector);

    if (this.hideElementTarget.value === this.hideElementTarget.dataset.hideValue) {
      target_to_hide.forEach((e) => e.value = null);
      target_to_hide.forEach((e) => e.classList.add('tw-hidden'));
      toggle_targets.forEach((e) => e.classList.remove('tw-hidden'));
    } else {
      toggle_targets.forEach((e) => e.value = null);
      toggle_targets.forEach((e) => e.classList.add('tw-hidden'));
      target_to_hide.forEach((e) => e.classList.remove('tw-hidden'));
    }

    if(hiding_target.value === 'social_link_hero') {
      this.element.querySelector('.text_order').classList.add('tw-hidden');
    }
  }
}