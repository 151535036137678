import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import { get } from '@rails/request.js';

const reservation_id = window.location.pathname.split('/').pop();

export default class extends Controller {
  static targets = [
    'datePicker', 'quantityPickerContainer',
    'quantityPickerInput', 'timePickerContainer',
    'timePickerInput', 'submitButton', 'form',
    'specialInstructionsContainer'
  ];

  calendar_instance = '';

  async fetchUnavailableDates() {
    // const _self = this;

    const response = await get(
      `/stores/${
        this.element.dataset.storeId
      }/reservations/${
        reservation_id
      }/unavailable-dates`
    );

    if (response.ok) {
      const body = await response.text

      return JSON.parse(body);
    } else {
      console.log(response)
    }
  }

  async fetchUnavailableHours(date) {
    const response = await get(
      `/stores/${
        this.element.dataset.storeId
      }/reservations/${
        reservation_id
      }/unavailable-hours?date=${date}`
    );

    if (response.ok) {
      const body = await response.text

      return JSON.parse(body);
    } else {
      console.log(response)
    }
  }

  async fetchAvailableQuantity(date, hour) {
    const response = await get(
      `/stores/${
        this.element.dataset.storeId
      }/reservations/${
        reservation_id
      }/available-quanitity?date=${date}&hour=${hour}`
    );

    if (response.ok) {
      const body = await response.text

      // 6. Unavailable quantity are hidden
      this.createQuantityOption(JSON.parse(body));
    } else {
      console.log(response)
    }
  }

  createQuantityOption(quantity) {
    this.quantityPickerInputTarget.innerHTML = '';

    for(let i = 1; i <= quantity; i++) {
      let option = document.createElement( 'option' );
      option.value = option.text = i;
      this.quantityPickerInputTarget.add(option);
    }
  }

  // Gather unavilable hours
  async dateSelected() {
    this.timePickerContainerTarget.classList.remove('tw-hidden')

    const unavailable_hours = await this.fetchUnavailableHours(
      this.calendar_instance.latestSelectedDateObj
    );

    // 4. Unavailable hours are removed
    this.hideUnavailableHours(unavailable_hours);
  }

  // Remove unavailable hours from the list
  hideUnavailableHours(unavailable_hours) {
    const _self = this;

    unavailable_hours.forEach(hour => {
      for(let option of _self.timePickerInputTarget.children) {
        if(option.value == hour) {
          _self.timePickerInputTarget.removeChild(
            _self.timePickerInputTarget.querySelector(`option[value="${hour}"]`)
          );
        }
      }
    })
  }

  showHideElements() {
    const _self = this;

    _self.submitButtonTarget.disabled = true

    this.timePickerInputTarget.addEventListener('change', e => {
      _self.quantityPickerContainerTarget.classList.remove('tw-hidden')
      _self.specialInstructionsContainerTarget.classList.remove('tw-hidden')

      // 5. unavailable quantity is fetched
      _self.fetchAvailableQuantity(
        this.calendar_instance.latestSelectedDateObj,
        e.currentTarget.value
      )

      _self.submitButtonTarget.disabled = false
    })
  }

  async connect() {
    const _self = this;
    
    this.showHideElements()

    // 1. First unavailable dates are fetched
    this.calendar_instance = await flatpickr(this.datePickerTarget, {
      defaultDate: new Date(),
      minDate: new Date(),
      // 2. Unavailable dates are hidden
      disable: await _self.fetchUnavailableDates(),
      dateFormat: "Y-m-d",
      // 3. The date is selected
      onChange: function(selectedDates, dateStr, instance) {
        this.selected_date = dateStr;
        _self.dateSelected()
      }
    });

    this.submitButtonTarget.addEventListener('click', e => {
      _self.formTarget.submit();
    })
  }
}
