import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['addressInput', 'coordinateInputLat', 'coordinateInputLng'];
  static values = { googleMapsApiKey: String }

  initializeMap() { this.autocomplete() }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.addressInputTarget)

      this._autocomplete.setFields(['address_components', 'name', 'place_id', 'international_phone_number', 'type', 'geometry'])
      this._autocomplete.setTypes(['bar', 'restaurant', 'bakery', 'night_club', 'cafe'])
      this._autocomplete.setComponentRestrictions({ country: 'pl' })

      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    }
    return this._autocomplete
  }

  locationChanged() {
    let place = this.autocomplete().getPlace()

    if(place.hasOwnProperty('geometry')) {
      this.coordinateInputLatTarget.value = place.geometry.location.lat();
      this.coordinateInputLngTarget.value = place.geometry.location.lng();
    }

    if (!place.name) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }
}
