import { Controller } from "@hotwired/stimulus";

export default class ItemsSearchSubmitController extends Controller {
  static values = { menuCategoryId: String }
  static targets = ['items']

  connect() {
    var items_form = document.querySelector(`#items_form_${this.menuCategoryIdValue}`);
    if(!items_form) return;

    this.itemsTarget.querySelectorAll('.items').forEach((item) => {
      item.addEventListener('click', e => {
        items_form.querySelectorAll('option').forEach(option => {
          if(option.value == e.currentTarget.dataset.itemId) {
            option.selected = true
            items_form.requestSubmit();
          }
        })
      })
    })
  }
}
