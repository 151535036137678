import { Controller } from '@hotwired/stimulus';

export default class PageHeaderComponent extends Controller {
  static targets = ['menu'];
  
  connect() {

    if (this.menuTarget.state === 'open') this.menuTarget.classList.remove('tw-hidden')
  }

  close() {
    this.menuTarget.classList.toggle('tw-hidden')
  }

  open() {
    this.menuTarget.classList.toggle('tw-hidden')
  }
}
