import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js';

export default class extends Controller {
  async newNotifications() {
    await get(
      '/admin/notifications?q[status_not_eq]=2',
      { responseKind: 'turbo-stream' }
    )
  }

  connect() { this.newNotifications() }
}
