import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { post } from "@rails/request.js";

export default class DragDrop extends Controller {
  connect() {
    const el = this.element;
    var sortable_type = el.dataset.sortableType;
    var pageParams = new URLSearchParams(document.location.search);
    var per_page = pageParams.get("per");
    var page = pageParams.get("page");

    new Sortable(el, {
      animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
      easing: "cubic-bezier(1, 0, 0, 1)",
      handle: ".draggable-element",
      onEnd: function (evt) {
        var list = [...evt.to.children].map((el, index) => ({
          index: index,
          data_id: el.dataset.dataId,
          parent_id: el.dataset.parentId,
        }));
        setPosition(list);
      },
    });

    async function setPosition(params) {
      let _self = this;

      const response = await post(
        `${window.location.origin}/admin/${sortable_type}/reorder-position`,
        {
          responseKind: "turbo-stream",
          body: JSON.stringify({ data: params, page: page, per: per_page }),
        }
      );

      if (response.ok) {
        const body = await response.text;
      } else {
        console.log("error");
      }
    }
  }
}
