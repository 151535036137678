import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['options']

  select(button) {
    button.classList.toggle('tw-bg-transparent')
    button.classList.toggle('tw-bg-jalappeno-primary')

    button.querySelector('span').classList.toggle('tw-text-jalappeno-primary')
    button.querySelector('span').classList.toggle('tw-text-white')
  }

  connect() {
    this.optionsTarget.querySelectorAll('input:checked').forEach((input) => {
      this.select(input.closest('.grid-select-button'))
    })

    this.optionsTarget.querySelectorAll('input').forEach((input) => {
      input.addEventListener('click', (e) => {
        this.select(e.target.closest('.grid-select-button'))
      })
    })
  }
}
