import { Controller } from '@hotwired/stimulus';
import { destroy } from '@rails/request.js';

export default class extends Controller {
  static targets = ['label', 'input', 'dropArea', 'removeFile', 'existingFile', 'multiImage'];

  initDropArea() {
    const dropArea = this.labelTarget;

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, this.preventDefaults, false);
    });

    ['dragenter', 'dragover'].forEach(eventName => {
      dropArea.addEventListener(eventName, this.highlight, false);
    });

    ['dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, this.unhighlight, false);
    });

    dropArea.addEventListener('drop', this.handleDrop.bind(this), false);
    this.inputTarget.addEventListener('change', this.handleDrop.bind(this), false);
    this.labelTarget.addEventListener('click', this.handleClick.bind(this), false);
  }

  async destroyFile(url, target) {
    const response = await destroy(url);
    let _self = this;
    if (response.ok) {
      _self.existingFileTarget.removeChild(target);
    } else {
      console.log(response)
    }
  }

  handleRemoveFile(event) {
    event.preventDefault();

    if(this.inputTarget.multiple) {
      this.destroyFile(event.currentTarget.dataset.destroyUrl, event.currentTarget.parentElement)
    } else {
      this.existingFileTarget.classList.add('tw-hidden');
      this.inputTarget.type = 'hidden';
      this.inputTarget.value = '';
      this.inputTarget.closest('form').submit();
    }
  }

  handleDrop(event) {
    // event.preventDefault();
    const dt = event.dataTransfer;
    const files = dt ? dt.files : event.target.files;

    this.inputTarget.files = files;

    this.handleFiles(files);
  }

  handleFiles(files) {
    files = [...files];

    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = this.previewFile.bind(this, file);
      reader.readAsDataURL(file);
    });
  }

  hasExtension(file, exts) {
    var fileName = file.name;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  previewFile(file) {
    if (this.existingFileTarget) { this.existingFileTarget.classList.add('tw-hidden'); }

    if (!this.hasExtension(file, ['.jpg', '.gif', '.png', '.svg', '.jpeg'])) {
      const filename = document.createElement('p');
      filename.classList.add('tw-p-5')
      filename.innerHTML = file.name;

      this.labelTarget.classList.remove('tw-w-64', 'tw-h-40');
      this.labelTarget.innerHTML = '';
      this.labelTarget.appendChild(filename);

      return;
    }


    const reader = new FileReader();
    reader.onloadend = () => {
      var img = document.createElement('img');
      img.style.objectFit = 'cover';
      img.style.maxHeight = '128px';
      img.src = reader.result;
      img.classList.add('tw-mt-2')
      // console.log(img.src);

      if(this.inputTarget.files.length > 1) {
        img.classList.add('tw-rounded-lg');
        this.multiImageTarget.appendChild(img);
      } else {
        this.labelTarget.classList.remove('tw-w-64', 'tw-h-40');
        this.labelTarget.innerHTML = '';
        this.labelTarget.appendChild(img);
      }
    };
    reader.readAsDataURL(file);
  }

  handleClick(event) {
    event.preventDefault();
    this.inputTarget.click();
  }

  highlight(event) {
    event.currentTarget.classList.remove('tw-bg-gray-50');
    event.currentTarget.classList.add('tw-bg-jalappeno-primary');
  }

  unhighlight(event) {
    event.currentTarget.classList.add('tw-bg-gray-50');
    event.currentTarget.classList.remove('tw-bg-jalappeno-primary');
  }

  preventDefaults(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  connect() {
    this.initDropArea();
  }
}
