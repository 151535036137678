import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['postalCodeInput', 'addressInput', 'businessNameInput', 'addressResInput', 'cityInput', 'phoneNumberInput', 'placeIdInput', 'businessTypeInput'];
  static values = {
    googleMapsApiKey: String
  }

  initializeMap() { this.autocomplete() }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.addressInputTarget)

      this._autocomplete.setFields(['address_components', 'name', 'place_id', 'international_phone_number', 'type'])
      this._autocomplete.setTypes(['bar', 'restaurant', 'bakery', 'night_club', 'cafe'])
      this._autocomplete.setComponentRestrictions({ country: 'pl' })

      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    }
    return this._autocomplete
  }

  locationChanged() {
    let place = this.autocomplete().getPlace()
    if (!place.name) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    this.populateFields(place);
  }

  populateFields(place) {
    const placeTypes = ['hotel', 'restaurant', 'bar', 'cafe', 'salon', 'night_club', 'bakery', 'store']
    var streetAddress = ''
    var streetNumber = ''
    var city = ''
    var postalCode = ''

    place.address_components.forEach((component) => {
      if(component.types.includes('route')) { streetAddress = component.long_name }
      if(component.types.includes('street_number')) { streetNumber = component.long_name }
      if(component.types.includes('locality')) { city = component.long_name }
      if(component.types.includes('postal_code')) { postalCode = component.long_name }
    })

    this.businessNameInputTarget.value = place.name;
    this.addressResInputTarget.value = streetAddress + ' ' + streetNumber;
    this.cityInputTarget.value = city;
    this.phoneNumberInputTarget.value = place.international_phone_number;
    this.placeIdInputTarget.value = place.place_id;
    this.postalCodeInputTarget.value = postalCode;

    place.types.forEach((type) => {
      if(placeTypes.includes(type)) {
        var input = this.businessTypeInputTarget.querySelector(`input[data-options=${type}]`)
        input.checked = true

        var button = input.closest('.grid-select-button');
        button.classList.toggle('tw-bg-transparent')
        button.classList.toggle('tw-bg-jalappeno-primary')

        button.querySelector('span').classList.toggle('tw-text-jalappeno-primary')
        button.querySelector('span').classList.toggle('tw-text-white')
      }
    })
  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }

  connect() {
    window.dispatchMapsEvent = function (...args) {
      const event = document.createEvent("Events")
      event.initEvent("google-maps-callback", true, true)
      event.args = args
      window.dispatchEvent(event)
    }

    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }
}
