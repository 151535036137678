import { Controller } from "@hotwired/stimulus";

export default class MultiLocaleFields extends Controller {
  connect() {
    const container = this.element;

    let selectElement = container.querySelector("select");

    selectElement.setAttribute("data-previous-value", selectElement.value);

    selectElement.addEventListener("change", (event) => {
      let previousValue = selectElement.getAttribute("data-previous-value");
      let currentValue = selectElement.value;

      let previousInput = container.querySelector(`.${previousValue}_input`);
      if (previousInput) {
        previousInput.classList.toggle("tw-hidden");
      }

      let currentInput = container.querySelector(
        `input.${currentValue}_input, textarea.${currentValue}_input`
      );
      if (currentInput) {
        currentInput.classList.toggle("tw-hidden");
      }

      selectElement.setAttribute("data-previous-value", currentValue);
    });
  }
}
