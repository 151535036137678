import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal'];

  openModal(order_id) {
    let _self = this;

    window.addEventListener(
      'modalInitializationComplete', e => {

        _self.modalTarget.classList.toggle('tw-hidden');
        _self.modalTarget.classList.toggle('tw-flex');
      },
      true
    );
  }

  connect() {
    this.openModal()
  }
}
