import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['shareButton'];

  shareData(target) {
    return {
      title: target.dataset.shareTitle,
      text: target.dataset.shareDescription,
      url: target.dataset.shareLink,
    };
  }

  connect() {
    this.shareButtonTarget.addEventListener('click', (event) => {
      navigator.share(this.shareData(event.currentTarget));
    });
  }
}
