import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab'];

  clickTab(event) {
    this.makeActive(event.currentTarget);
  }

  makeActive(tab) {
    this.clearActive()

    tab.classList.remove('tw-border-transparent')
    tab.classList.add('tw-border-jalappeno-primary')
  }

  makeInactive(tab) {
    tab.classList.remove('tw-border-jalappeno-primary')
    tab.classList.add('tw-border-transparent')
  }

  clearActive() {
    let _self = this;
    this.tabTarget.querySelectorAll("a[role='tab']").forEach((e) => {
      _self.makeInactive(e)
    })
  }

  connect() {
    var tabParams = new URLSearchParams(window.location.search);
    if(tabParams.get('tab')) {
      this.makeActive(this.tabTarget.querySelector(`a[data-tab='${searchParams.get('tab')}']`))
    } else {
      this.makeActive(this.tabTarget.querySelector('a'))
    }
  }
}
